/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

// Images
import Pin from 'img/pin.inline.svg'

// Components
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Title = styled(ParseContent)`
  & h1,
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    position: relative;
    padding-bottom: 0;
    margin-bottom: 40px !important;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  @media screen and (max-width: 575px) {
    & h2 {
      margin-bottom: 10px !important;
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const StyledVacancies = styled.div``

const Vacancies: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpVacancy,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.vacanciesQueryQuery>(graphql`
    query vacanciesQuery {
      allWpVacancy(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalVacancyFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpVacancy.edges

  return (
    <StyledVacancies>
      <a id="vacancies" />
      <BlogBink posts={posts as BlogBinkPosts} id="vacancies" limit={Infinity}>
        <div className="container">
          <div className="py-lg-5 py-4">
            <Title content={fields.title || ''} />

            <BlogGrid fields={fields} />
          </div>
        </div>
      </BlogBink>
    </StyledVacancies>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Col = styled.div`
  @media screen and (max-width: 575px) {
    padding-bottom: 30px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row">
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <Col key={post.node.id} className="col-12">
            <BlogGridPost node={node} blogFields={fields} />
          </Col>
        )
      })}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpVacancy
}

const BlogGridPostExcerpt = styled(ParseContent)`
  & p {
    font-size: 14px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const BlogTitle = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.color.dark};
`

const StyledBlogGridPost = styled.div`
  padding: 50px;
  background-color: ${({ theme }) => theme.color.grey};

  @media screen and (max-width: 991px) {
    padding: 30px;
  }

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`

const Location = styled.div`
  @media screen and (min-width: 992px) {
    width: 100%;
  }

  & svg {
    margin-right: 5px;
    path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }

  & p {
    font-size: 16px;
    line-height: 20px;
    text-align: right;
  }

  @media screen and (max-width: 400px) {
    & p {
      font-size: 14px;
    }
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => (
  <StyledBlogGridPost>
    <div className="row">
      <div className="col-lg-6">
        <BlogTitle>{node.title}</BlogTitle>
        <BlogGridPostExcerpt content={node.vacancyrecap?.excerpt || ''} />
      </div>
      <div className="col-lg-6 pt-lg-0 pt-3 d-flex align-items-between flex-wrap justify-content-lg-end justify-content-between">
        <Location className="d-flex align-items-lg-start align-items-center justify-content-lg-end justify-content-start">
          <Pin />
          <ParseContent content={node.vacancyrecap?.location || ''} />
        </Location>
        <div className="mt-auto pt-sm-0 pt-4 ms-sm-0 ms-auto">
          <ButtonPrimary to={node.uri || '/'}>
            {blogFields.readmoretext}
          </ButtonPrimary>
        </div>
      </div>
    </div>
  </StyledBlogGridPost>
)

export default Vacancies
