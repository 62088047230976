/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

interface ProductSmallProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledProductSmall = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
`

const Container = styled.div`
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;

  @media screen and (max-width: 1199px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const Title = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  padding: 90px 70px 70px 70px;
  margin: 35px 0;

  @media screen and (max-width: 1199px) {
    padding: 70px 50px 50px;
  }

  @media screen and (max-width: 575px) {
    padding: 40px;
    margin: 25px 0;
  }

  @media screen and (max-width: 400px) {
    padding: 25px;
  }

  @media screen and (max-width: 350px) {
    padding: 15px;
    margin: 15px 0;
  }
`

const BlockTitle = styled(ParseContent)`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 30px;
    line-height: 30px;
    margin: 0;
  }

  & p {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 400px) {
    & p {
      font-size: 16px;
    }
  }
`

const BlockContent = styled(ParseContent)`
  & p {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 16px;
    line-height: 30px;
  }

  & li {
    list-style: none;
    position: relative;
    font-size: 16px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};

    &:before {
      content: '';
      position: absolute;
      top: 13px;
      left: -15px;
      background-color: ${({ theme }) => theme.color.dark};
      height: 4px;
      width: 4px;
      border-radius: 2px;
    }
  }

  @media screen and (max-width: 575px) {
    & p {
      margin-bottom: 0;
    }

    & li {
      font-size: 16px !important;
    }
  }

  @media screen and (max-width: 350px) {
    & ul {
      padding-left: 20px;

      & li {
        font-size: 14px !important;

        &:before {
          left: -15px;
        }
      }
    }
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  @media screen and (max-width: 991px) {
    width: 100%;

    & a {
      text-align: center;
      padding: 4px 20px;
    }
  }
`

const Extra = styled.div`
  & span {
    margin-left: 10px;
  }

  & a,
  button {
    padding: 0px 15px;
  }
`

const ProductSmall: React.FC<ProductSmallProps> = ({ fields }) => {
  const {
    allWpProduct,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.productSmallQueryQuery>(graphql`
    query productSmallQuery {
      allWpProduct(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProductFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProduct.edges

  const productIds: any = []

  fields.products?.forEach((product) => {
    productIds.push(product?.databaseId)
  })

  return (
    <StyledProductSmall>
      <Container className="container">
        <Title content={fields.title || ''} />
        <div className="px-xl-5">
          <BlogBink
            posts={posts as BlogBinkPosts}
            showIds={productIds}
            id="product-small-axign"
            limit={Infinity}
          >
            <Products fields={fields} />
          </BlogBink>
        </div>
        <Extra>
          <ParseContent content={fields.extratext || ''} />
        </Extra>
      </Container>
    </StyledProductSmall>
  )
}

export default ProductSmall

const Products = ({ fields }: any) => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post) => {
        const { node }: any = post
        return <Product fields={fields} node={node} />
      })}
    </>
  )
}

const Product = ({ fields, node }: any) => (
  <Block>
    <div className="row justify-content-between">
      <div className="col-xl-5 col-sm-6">
        <BlockTitle content={node?.productrecap?.title || ''} />
        <div className="pt-lg-5 pt-3 pe-lg-5 pe-3">
          <Plaatjie image={node?.productrecap?.image} alt="" />
        </div>
      </div>
      <div className="col-xl-5 col-sm-6 pt-sm-0 pt-3">
        <BlockContent content={node?.productrecap?.excerpt || ''} />
        <div className="pt-lg-4 d-flex flex-wrap">
          <StyledButtonPrimary
            className="me-lg-3 mb-lg-0 mb-2"
            to={node?.uri || '/'}
          >
            {fields.readmoretext}
          </StyledButtonPrimary>
        </div>
      </div>
    </div>
  </Block>
)
