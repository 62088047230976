/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import styled from 'styled-components'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

interface ProductPortfolioProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const StyledProductPortfolio = styled.div`
  background-color: ${({ theme }) => theme.color.light};
`

const Container = styled.div`
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;

  @media screen and (max-width: 1199px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  @media screen and (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media screen and (max-width: 575px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }
`

const Title = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: -50px;
    }
  }

  & h3 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: 30px;
    line-height: 30px;
  }

  @media screen and (max-width: 575px) {
    & h2 {
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  padding: 45px;
  margin: 35px 0;

  @media screen and (max-width: 1199px) {
    padding: 30px;
  }

  @media screen and (max-width: 575px) {
    padding: 20px;
    margin: 25px 0;
  }

  @media screen and (max-width: 400px) {
    padding: 15px;
  }

  @media screen and (max-width: 350px) {
    padding: 15px;
    margin: 15px 0;
  }
`

const BlockTitle = styled.div`
  color: ${({ theme }) => theme.color.dark};
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-family: ${({ theme }) => theme.font.family.primary};
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 10px;
`

const BlockContent = styled(ParseContent)`
  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 16px;
    line-height: 20px;
  }

  @media screen and (max-width: 575px) {
    & p {
      margin-bottom: 0;
    }
  }
`

const StyledButtonPrimary = styled(ButtonPrimary)`
  @media screen and (max-width: 991px) {
    width: 100%;

    & a {
      text-align: center;
      padding: 4px 20px;
    }
  }
`

const BlockWrapper = styled.div`
  margin: 0 -50px;

  @media screen and (max-width: 991px) {
    margin: 0;
  }
`

const Label = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-family: ${({ theme }) => theme.font.family.primary};
  color: ${({ theme }) => theme.color.dark};
  padding-bottom: 5px;

  @media screen and (max-width: 1199px) {
    padding-bottom: 5px;
  }
`

const ProductPortfolio: React.FC<ProductPortfolioProps> = ({ fields }) => {
  const {
    allWpProduct,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.productportfolioQueryQuery>(graphql`
    query productportfolioQuery {
      allWpProduct(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalProductFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpProduct.edges

  const productIds: any = []

  fields.products?.forEach((product) => {
    productIds.push(product?.databaseId)
  })

  // const blogBink = useBlogBink()

  return (
    <StyledProductPortfolio>
      <Container className="container">
        <Title content={fields.title || ''} />
        <BlockWrapper>
          <BlogBink
            posts={posts as BlogBinkPosts}
            showIds={productIds}
            id="product-small-axign"
            limit={Infinity}
          >
            <Products fields={fields} />
          </BlogBink>
        </BlockWrapper>
      </Container>
    </StyledProductPortfolio>
  )
}

export default ProductPortfolio

const Products = ({ fields }: any) => {
  const blogBink = useBlogBink()

  return (
    <>
      {blogBink.posts.map((post, index) => {
        const { node: product }: any = post
        return (
          <Block key={index}>
            <div className="row justify-content-between">
              <div className="col-xl-2 col-lg-3 col-md-4 d-flex justify-content-center align-items-center">
                <div className="px-xl-0 px-3">
                  <Plaatjie image={product?.productrecap?.image} alt="" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-8">
                <div className="col-xl-4">
                  <Label>Product</Label>
                  <BlockTitle>{product?.title}</BlockTitle>
                </div>
                <div className="col-xl-8 pt-xl-0 pt-3">
                  <Label>Description</Label>
                  <BlockContent
                    content={product?.productrecap?.portfoliodescription || ''}
                  />
                </div>
              </div>
              <div className="pt-lg-0 pt-4 col-xl-3 col-lg-3 d-flex justify-content-xl-end justify-content-center align-items-center">
                <StyledButtonPrimary
                  className="me-lg-3 mb-lg-0 mb-2"
                  to={product?.uri || '/'}
                >
                  {fields.readmoretext}
                </StyledButtonPrimary>
              </div>
            </div>
          </Block>
        )
      })}
    </>
  )
}
