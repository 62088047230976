import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// Images
import check from 'img/check.svg'

// Components
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Title = styled(ParseContent)`
  & h1,
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    position: relative;
    padding-left: 50px;
    padding-bottom: 0;
    margin-bottom: 40px !important;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 3px;
      background-color: ${({ theme }) => theme.color.primary};
      left: 0px;
    }
  }

  @media screen and (max-width: 1199px) {
    & h1,
    h2 {
      padding-left: 20px;
    }
  }

  @media screen and (max-width: 575px) {
    & h1,
    h2 {
      margin-bottom: 20px !important;
      font-size: 30px;
      line-height: 40px;
    }
  }
`

const FilterContainer = styled.div`
  background-color: ${({ theme }) => theme.color.grey};
  height: 100%;
  position: relative;

  &:before {
    content: '';
    background-color: ${({ theme }) => theme.color.grey};
    height: 100%;
    width: 2000px;
    left: -2000px;
    top: 0;
    position: absolute;
  }

  @media screen and (max-width: 991px) {
    &:after {
      content: '';
    }
  }
`

const FilterTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 20px;
  line-height: 30px;
  color: ${({ theme }) => theme.color.primary};
`

const BlogContent = styled(ParseContent)`
  & p {
    font-size: 16px;
    line-height: 30px;
  }
`

const BlogColumnLabel = styled.div`
  font-size: 20px;
  line-height: 30px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

const BlogColumnLabels = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #dadada;
    width: 100%;
    left: 0;
    bottom: 0;
  }
`

const Partners: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPartner,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.partnersQueryQuery>(graphql`
    query partnersQuery {
      allWpPartner(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPartnerFragment
          }
        }
      }
    }
  `)
  const [customFilters, setCustomFilters] = React.useState([])
  const [posts, setPosts] = React.useState([])

  React.useEffect(() => {
    // @ts-ignore
    setPosts(allWpPartner.edges)
  }, [])

  React.useEffect(() => {
    if (customFilters.length !== 0) {
      // @ts-ignore
      const checkPosts = allWpPartner.edges
      const checkedPosts = checkPosts.filter((p) =>
        // @ts-ignore
        customFilters.includes(p.node.partnerrecap.region)
      )
      // @ts-ignore
      setPosts(checkedPosts)
    } else {
      // @ts-ignore
      setPosts(allWpPartner.edges)
    }
  }, [customFilters])

  return (
    <section>
      <BlogBink posts={posts as BlogBinkPosts} id="partners" limit={Infinity}>
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <FilterContainer className="py-xl-5 py-lg-4 py-3">
                <Title content={fields.title || ''} />
                {/* <FilterTitle>Filter by region</FilterTitle>
                <BlogFilters
                  customFilters={customFilters || []}
                  setCustomFilters={setCustomFilters}
                /> */}
              </FilterContainer>
            </div>
            <div className="col-lg-8 py-lg-5 py-4">
              <div className="ps-lg-5">
                <div className="pb-lg-3 pb-2">
                  <BlogContent content={fields.description || ''} />
                </div>
                <BlogColumnLabels className="row py-lg-4 py-3">
                  <div className="col-10">
                    <div className="row w-100">
                      <div className="col-6">
                        <BlogColumnLabel>Company</BlogColumnLabel>
                      </div>
                      <div className="col-6">
                        <BlogColumnLabel>Website</BlogColumnLabel>
                      </div>
                    </div>
                  </div>
                </BlogColumnLabels>
                <BlogGrid fields={fields} />
              </div>
            </div>
          </div>
        </div>
      </BlogBink>
    </section>
  )
}

const StyledBlogFilter = styled.div<{ selected: boolean }>`
  padding-left: 25px;
  font-size: 16px;
  line-height: 30px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  color: ${({ theme }) => theme.color.dark};
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 14px;
    width: 14px;
    border: 1px solid #dadada;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    left: 0px;
  }

  ${({ selected }) =>
    selected &&
    css`
      &:after {
        content: '';
        background-image: url(${check});
        background-size: contain;
        background-repeat: no-repeat;
        height: 12px;
        width: 12px;
        position: absolute;
        top: 1px;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        left: 1px;
      }
    `}
`

interface BlogFiltersProps {
  setCustomFilters: Function
  customFilters: Array<String>
}

const BlogFilters: React.FC<BlogFiltersProps> = ({
  customFilters,
  setCustomFilters,
}) => {
  const handleClick = (value: string) => {
    let newFilter = []

    if (customFilters.includes(value)) {
      newFilter = customFilters.filter((i) => i !== value)
    } else {
      // @ts-ignore
      newFilter = [...customFilters]
      newFilter.push(value)
    }

    setCustomFilters(newFilter)
  }

  return (
    <div className="pt-lg-2">
      <div>
        <StyledBlogFilter
          role="button"
          tabIndex={-1}
          onKeyDown={() => handleClick('usa')}
          onClick={() => handleClick('usa')}
          selected={customFilters.includes('usa')}
        >
          United States of America
        </StyledBlogFilter>
        <StyledBlogFilter
          role="button"
          tabIndex={-1}
          onKeyDown={() => handleClick('eu')}
          onClick={() => handleClick('eu')}
          selected={customFilters.includes('eu')}
        >
          Europe/Middle East/Africa
        </StyledBlogFilter>
        <StyledBlogFilter
          role="button"
          tabIndex={-1}
          onKeyDown={() => handleClick('asia')}
          onClick={() => handleClick('asia')}
          selected={customFilters.includes('asia')}
        >
          Asia
        </StyledBlogFilter>
      </div>
    </div>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Col = styled.div`
  @media screen and (max-width: 575px) {
    padding-bottom: 30px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`

const NoResult = styled.div`
  padding: 50px 15px;
  font-size: 30px;
  line-height: 40px;
  font-weight: ${({ theme }) => theme.font.weight.light};
  color: ${({ theme }) => theme.color.primaryDark};

  @media screen and (max-width: 575px) {
    font-size: 20px;
    padding: 20px 15px 0 15px;
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row">
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <Col key={post.node.id} className="col-12">
            <BlogGridPost node={node} blogFields={fields} />
          </Col>
        )
      })}
      {blogBink.posts.length === 0 && (
        <NoResult>Oops, looks like there are no results!</NoResult>
      )}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPartner
}

const BlogTitle = styled.div`
  font-weight: ${({ theme }) => theme.font.weight.light};
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.color.dark};

  & a {
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`

const StyledBlogGridPost = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    background-color: #dadada;
    width: calc(100% + 20px);
    left: -10px;
    bottom: 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  cursor: pointer;
  height: 95px;
  z-index: -1;
  position: relative;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 575px) {
    height: 70px;
  }
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node }) => (
  <StyledBlogGridPost className="py-sm-0 py-2">
    <div className="row">
      <div className="col-sm-10 col-9 d-flex align-items-center">
        <div className="row w-100">
          <div className="col-sm-6 d-flex align-items-center">
            <BlogTitle>{node.partnerrecap?.company}</BlogTitle>
          </div>
          <div className="col-sm-6 d-flex align-items-center">
            <BlogTitle>
              <a href={node.partnerrecap?.website?.url} target="__blank">
                {node.partnerrecap?.website?.title}
              </a>
            </BlogTitle>
          </div>
        </div>
      </div>
      <div className="col-sm-2 col-3">
        <StyledPlaatjie
          onClick={() => navigate(node.uri || '/')}
          image={node.partnerrecap?.image}
          alt=""
        />
      </div>
    </div>
  </StyledBlogGridPost>
)

export default Partners
