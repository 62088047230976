import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery, navigate } from 'gatsby'

// Components
import Plaatjie from '@ubo/plaatjie'
import ButtonPrimaryTransparent from 'components/elements/Buttons/ButtonPrimaryTransparent'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlogBink, { useBlogBink, BlogBinkPosts } from '@ubo/blog-bink'

interface BlogProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Title = styled(ParseContent)`
  & h1,
  h2 {
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 50px;
    font-family: ${({ theme }) => theme.font.family.secondary};
    position: relative;
    padding-bottom: 0;
    margin-bottom: 40px !important;
  }

  @media screen and (max-width: 575px) {
    & h1,
    h2 {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 10px !important;
    }
  }
`

const StyledOtherNews = styled.section`
  background-color: ${({ theme }) => theme.color.grey};
`

const NewsOther: React.FC<BlogProps> = ({ fields }) => {
  const {
    allWpPost,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.newsotherQueryQuery>(graphql`
    query newsotherQuery {
      allWpPost(sort: { order: DESC, fields: date }) {
        edges {
          node {
            ...generalPostFragment
          }
        }
      }
    }
  `)

  const posts: unknown = allWpPost.edges

  return (
    <StyledOtherNews>
      <BlogBink posts={posts as BlogBinkPosts} id="othernews" limit={2}>
        <div className="container">
          <div className="py-lg-5 py-3">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <Title content={fields.title || ''} />
                <BlogGrid fields={fields} />
              </div>
            </div>
          </div>
        </div>
      </BlogBink>
    </StyledOtherNews>
  )
}

interface BlogGridProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
}

const Col = styled.div`
  @media screen and (max-width: 575px) {
    padding-bottom: 30px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
`

const BlogGrid: React.FC<BlogGridProps> = ({ fields }) => {
  const blogBink = useBlogBink()

  return (
    <div className="row pt-lg-4 pt-2">
      {blogBink.posts.map((post) => {
        const { node }: any = post

        return (
          <Col key={post.node.id} className="col-md-6">
            <BlogGridPost node={node} blogFields={fields} />
          </Col>
        )
      })}
    </div>
  )
}

interface BlogGridPostProps {
  // eslint-disable-next-line
  blogFields: GatsbyTypes.WpPage_Flexcontent_Flex_PostTypes
  node: GatsbyTypes.WpPost
}

const BlogGridPostExcerpt = styled(ParseContent)`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 120px;
  max-width: 100%;

  & p {
    font-size: 14px;
    line-height: 30px;
    font-weight: ${({ theme }) => theme.font.weight.light};
  }
`

const BlogTitle = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 18px;
  line-height: 30px;
  color: ${({ theme }) => theme.color.dark};
`

const StyledBlogGridPost = styled.div`
  padding: 0 0 0 50px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }
`

const StyledPlaatjie = styled(Plaatjie)`
  cursor: pointer;
  height: 250px;
`

const BlogDate = styled.div`
  color: #bcbcbc;
  font-size: 14px;
  line-height: 25px;
  font-weight: ${({ theme }) => theme.font.weight.light};
`

const BlogGridPost: React.FC<BlogGridPostProps> = ({ node, blogFields }) => {
  const currentRawDate = new Date(node.date || '').toLocaleDateString('en-US', {
    month: 'long',
    year: 'numeric',
  })

  const currentDate =
    currentRawDate.charAt(0).toUpperCase() + currentRawDate.slice(1)

  return (
    <StyledBlogGridPost>
      <StyledPlaatjie
        onClick={() => navigate(node.uri || '/')}
        image={node.recap?.image}
        alt=""
      />
      <BlogTitle className="pt-lg-4">{node.title}</BlogTitle>
      <BlogDate className="pb-lg-2 pb-1">{currentDate}</BlogDate>
      <BlogGridPostExcerpt content={node.recap?.excerpt || ''} />
      <div className="mt-3 d-flex justify-content-end">
        <ButtonPrimaryTransparent to={node.uri || '/'}>
          {blogFields.readmoretext}
        </ButtonPrimaryTransparent>
      </div>
    </StyledBlogGridPost>
  )
}

export default NewsOther
